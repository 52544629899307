import React from "react";
import styled from "styled-components";
import Section from "../../section";
import {CONTAINER, H1, H2, H3, H4, PARAGRAPH} from "../../../styles/Global";
import ReactHtmlParser from 'react-html-parser';
import {GatsbyImage} from "gatsby-plugin-image";
import {processImage} from "../../../helpers";
import {BREAKPOINTS} from "../../../constants";

const Index = ({data}) => {

  let article_body_elements = new ReactHtmlParser(data.blog.body.value, {
    transform: function transform(node) {
      if (node.name === 'drupal-media') {
        let uuid = node.attribs['data-entity-uuid'];

        return data.allMediaImage.nodes.map((node, index) => {
          if (uuid && node.drupal_id === uuid) {
            const image = processImage(node);
            return (
              <GatsbyImage
                key={index}
                image={image.gatsbyImageData}
                backgroundColor="transparent"
                alt={image.alt}
                objectFit="cover"
                loading="lazy"
              />
            )
          }

          return undefined
        })
      }
    }
  });

  return (
    <Wrapper>
      <Container>
        <Content>
          {article_body_elements}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Index;

const Wrapper = styled(Section)`
  background: rgba(65, 45, 219, 1);
  height: auto;
  padding: 60px 0;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    padding: 100px 0;
  }
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  
  h1 {
    ${H1}
    color: #ffffff;
  }
  
  h2 {
    ${H2}
    color: #ffffff;
  }
  
  h3 {
    ${H3}
    color: #ffffff;
  }
  
  h4 {
    ${H4}
    color: #ffffff;
  }
  
  p {
    ${PARAGRAPH}
    margin: 0 20px 30px;
    color: #ffffff;
  }
  
  img {
    padding-bottom: 30px;
  }
  
  code {
    background: rgba(26,18,88,1);
  }
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    p {
      margin: 0 20% 30px;
    }
    
    img {
      padding-bottom: 50px;
    }
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    p {
      margin-bottom: 50px;
    }
    
    img {
      padding-bottom: 50px;
    }
  }
`;