import React from 'react';
import PropTypes from "prop-types";
import Helmet from 'react-helmet';

const MetaTags = ({ blog }) => {
  const { metatags, created, changed, title, body, relationships: {uid: { field_fullname }} } = blog;

  const defaultDescription = body.processed.replace(/(<([^>]+)>)/gi, "").substring(0, 255);

  return(
    <Helmet>
      <title>{metatags && metatags.title ? metatags.title : title ? `${title} | JLTech` : 'JLTech Blog'}</title>
      <meta name="description" content={metatags ? metatags.description : defaultDescription}/>

      {metatags && metatags.keywords ? (
        <meta name="keywords" content={metatags.keywords}/>
      ) : null}

      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://jlte.ch"
            },
            "datePublished": "${created}",
            "dateModified": "${changed}",
            "headline": "${title}",
            "image": "${blog.relationships.field_featured_image.relationships.field_media_image.localFile.url}",
            "articleBody": "${body.processed}",
            "publisher": {
              "@type": "Organization",
              "name": "JLTech LLC",
              "url": "https://jlte.ch",
              "logo": "https://jlte.ch/icons/icon-512x512.png"
            },
            "author": {
              "@type": "Person",
              "name": "${ field_fullname }",
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default MetaTags;

MetaTags.defaultProps = {
  metatags: {}
}