import React, {useState} from "react";
import styled from "styled-components";
import Section from "../../section";
import {CONTAINER, H3, PARAGRAPH} from "../../../styles/Global";
import {BREAKPOINTS} from "../../../constants";
import { Button } from 'antd';
import {processComments} from "../../../helpers";
import Comment from "../../comments/Comment";
import PostComment from "../../comments/PostComment";

const Index = (props) => {
  const {data} = props;
  const [open, setOpen] = useState(false);

  let comments = processComments(data.comments.nodes);

  const commentProps = {
    contentType: 'article_comment',
    entityType: 'node',
    fieldName: 'field_article_comments',
    entityId: data.blog.entity_id,
  }

  // Don't show comments if they are closed.
  if (data.blog[commentProps.fieldName] && data.blog[commentProps.fieldName].status === 1) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Title>Article Comments</Title>
        <AddComment type="text" onClick={() => setOpen(!open)}>Add a Comment</AddComment>
        {open ? (
          <PostComment commentProps={commentProps} />
        ) : null}
        {comments.length ? comments.map((comment, index) => {
          return (
            <Comment key={index} comment={comment} commentProps={commentProps} />
          );
        }) : null}
      </Container>
    </Wrapper>
  );
};

export default Index;

const Wrapper = styled(Section)`
  background: rgba(241, 240, 245, 1);
  height: auto;
  padding: 60px 0;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    padding: 100px 0;
  }
`;

const Container = styled.div`
  ${CONTAINER}
  height: 100%;
`;

const Title = styled.h2`
  ${H3}
  margin-bottom: 40px;
`;

const AddComment = styled(Button)`
  ${PARAGRAPH}
  height: auto;
`


