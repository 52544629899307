import React from "react";
import {graphql} from "gatsby";
import styled from "styled-components";
import Layout from "../../components/Layout";
import Header from "../../components/blog/header";
import Body from "../../components/blog/body";
import Related from "../../components/blog/related";
import Comments from "../../components/blog/comments";
import Contact from "../../components/contact";
import MetaTags from "../../components/blog/MetaTags";

const Index = (props) => {
  const { data } = props;
  if (!data || (data && !data.blog)) {
    return null
  }

  return (
    <Layout helmet={<MetaTags blog={data.blog} />}>
      <Header {...props} />
      <Body {...props} />
      <Related {...props} />
      <Comments {...props} />
      <Chat />
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query($id: String!, $slug: String!, $category: String, $images: [String]) {
    blog: nodeArticle(path: {alias: {eq: $slug}}) {
      entity_id: drupal_internal__nid
      title
      body {
        value
        processed
      }
      created
      field_article_comments {
        status
      }
      metatags: field_meta_tags {
        title
        description
        keywords
      }
      relationships {
        uid {
          name
          field_fullname
        }
        field_category {
          drupal_id
          name
        }
        field_featured_image {
          field_media_image {
            alt
            title
          }
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    allMediaImage(filter: {drupal_id: {in: $images}}) {
      nodes {
        drupal_id
        relationships {
          field_media_image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        field_media_image {
          alt
          title
        }
      }
    }
    related: allNodeArticle(
      limit: 3, sort: {fields: created, order: DESC},
      filter: {relationships: {field_category: {drupal_id: {eq: $category}}}, drupal_id: {ne: $id}}
    ) {
      nodes {
        title
        created
        body {
          summary
        }
        path {
          alias
        }
        relationships {
          field_featured_image {
            field_media_image {
              alt
              title
            }
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
    comments: allCommentArticleComment(
      sort: {order: ASC, fields: created}
      filter: {relationships: {entity_id: {drupal_id: {eq: $id}}}}
    ) {
      nodes {
        entity_id: drupal_internal__cid
        drupal_id
        created
        subject
        comment_body {
          value
        }
        thread
        relationships {
          pid {
            drupal_id
          }
        }
      }
    }
  }
`;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;
