import React from "react";
import styled from "styled-components";
import Section from "../../section";
import {CONTAINER, H3, H6, PARAGRAPH} from "../../../styles/Global";
import {BREAKPOINTS, PATHS} from "../../../constants";
import { Row, Col } from 'antd';
import {processImage} from "../../../helpers";
import Img from "gatsby-image/index";
import moment from "moment";
import {Link} from "gatsby";

const Index = ({data}) => {
  // Don't return related articles if there are none.
  if (!data.related.nodes.length) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Title>Related Articles</Title>
        <Row>
          {data.related.nodes.map((node, index) => {
            const image = processImage(node, 'field_featured_image');
            return (
              <Col span={8}>
                <Row>
                  <Col span={12}>
                    <FeaturedImage
                      fluid={image.fluid}
                      alt={image.alt}
                      className="featured"
                      objectFit="cover"
                      loading="eager"
                    />
                  </Col>
                  <Col span={12}>
                    <Created>
                      {moment(node.created).format("ll")}
                    </Created>
                    <BlogTitle>
                      <Link to={`/blogs${node.path.alias}/`}>
                        {node.title}
                      </Link>
                    </BlogTitle>
                    <BlogSummary>
                      {node.body.summary}
                    </BlogSummary>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Index;

const Wrapper = styled(Section)`
  background: rgba(241, 240, 245, 1);
  height: auto;
  padding: 60px 0;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    padding: 100px 0;
  }
`;

const Container = styled.div`
  ${CONTAINER}
  height: 100%;
`;

const FeaturedImage = styled(Img)`
  margin-right: 20px;
`;

const Title = styled.h2`
  ${H3}
  margin-bottom: 40px;
`;

const Created = styled.p`
  ${PARAGRAPH}
`

const BlogTitle = styled.h3`
  ${H6}
`;

const BlogSummary = styled.p`
  ${PARAGRAPH}
`