import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../../section";
import {CONTAINER, H1, LEADTEXT} from "../../../styles/Global";
import {PATHS, BREAKPOINTS} from "../../../constants";
import {processImage} from "../../../helpers";
import {GatsbyImage} from "gatsby-plugin-image";
import {HiOutlineArrowCircleLeft} from "@react-icons/all-files/hi/HiOutlineArrowCircleLeft";
import {Link} from "gatsby";

const Index = ({data, ...props}, ref) => {
  const image = processImage(data.blog, 'field_featured_image');

  return (
    <Wrapper {...props} ref={ref}>
      <Header>
        <Content>
          <Back to={PATHS.blog}>
            <HiOutlineArrowCircleLeft />
          </Back>
          <div>
            <Info>
              Written by{" "}
              {data.blog.relationships.uid ? (
                <Author>
                  {data.blog.relationships.uid.name}
                </Author>
              ) : null}
              {data.blog.relationships.field_category ? (
                <>
                  {" "}in{" "}
                  <Category>
                    {data.blog.relationships.field_category.name}
                  </Category>
                </>
              ) : null}
            </Info>
            <Title>
              {data && data.blog ? data.blog.title : null}
            </Title>
          </div>
        </Content>
        <Background>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt ? image.alt : data.blog.title}
            className="featured"
            objectFit="cover"
            style={{overflow: 'hidden', height: '100%', width: '100%'}}
            loading="eager"
          />
        </Background>
        <Foreground />
      </Header>
    </Wrapper>
  );
};

export default forwardRef(Index);

const Wrapper = styled(Section)`
  background: rgba(65, 45, 219, 1);
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: 60vh;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    height: 40vh;
  }
`;

const Back = styled(Link)`
  padding: 50px 0;
  display: block;
  position: relative;
  font-size: 30px;
  color: #ec3917;
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    font-size: 2vw;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, #412ddb 0%, #3a28c500 100%) 0% 0% no-repeat padding-box;
`;

const Content = styled.div`
  ${CONTAINER}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Info = styled.div`
  ${LEADTEXT}
  color: #ffffff;
  padding-bottom: 30px;
`;

const Author = styled.span`
  color: #ec3917;
`;

const Category = styled.span`
  color: #ec3917;
`;

const Title = styled.h1`
  ${H1}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;
