import React, {useState} from "react";
import {TextAreaSubmit, MessageField, TextField, FormSubmit} from "../../styles/Forms";
import styled from "styled-components";

const PostComment = (props) => {
  const {contentType, entityType, fieldName, entityId, parentId} = props.commentProps;

  let defaultComment = {
    subject: {value: null},
    comment_body: {value: null},
    comment_type: contentType,
    entity_id: [{target_id: entityId}],
    entity_type: {value: entityType},
    field_name: {value: fieldName},
    pid: parentId ? [{target_id: parentId}] : null
  }

  const [comment, setComment] = useState(defaultComment);

  const url = `${process.env.GATSBY_API_URL}/comment`;

  const submitForm = async (event) => {
    event.preventDefault();

    const result = await fetch(url, {
      body: JSON.stringify(comment),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    if (!result.ok) {
      console.log("Something went wrong!");
    } else {
      console.log("Message Submitted Successfully");
      setComment(defaultComment);
    }
  };

  return (
    <Wrapper>
      <form onSubmit={submitForm}>
        <TextField
          dark={true}
          placeholder="Subject"
          bordered={false}
          value={comment.subject.value}
          onChange={(e) => setComment({...comment, ...{subject: {value: e.target.value}}})}
        />
        <TextAreaSubmit>
          <MessageField
            dark={true}
            placeholder="Type your message…"
            bordered={false}
            rows={6}
            value={comment.comment_body.value}
            onChange={(e) => setComment({...comment, ...{comment_body: {value: e.target.value}}})}
          />
          <FormSubmit type="submit" onClick={submitForm}>
            Submit
          </FormSubmit>
        </TextAreaSubmit>
      </form>
    </Wrapper>
  );
};

export default PostComment;

const Wrapper = styled.div`
  padding: 20px;
`