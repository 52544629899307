import React, {useState} from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import styled from "styled-components";
import {Button} from "antd";
import {H5, PARAGRAPH, SMALL} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";
import PostComment from "./PostComment";

const Comment = ({comment, ...props}) => {
  const [reply, setReply] = useState(false);
  const comment_body_elements = new ReactHtmlParser(comment.comment_body.value);
  const depth = comment.thread.split(".");

  return (
    <Wrapper>
      {depth.length > 1 ? (<Indent depth={depth.length}></Indent>) : null}
      <Body>
        <Subject>{comment.subject} <Created>{moment(comment.created).format("ll")}</Created></Subject>
        {comment_body_elements}

        <Actions>
          {depth.length < 3 ? (<Reply type="text" onClick={() => setReply(!reply)}>Reply</Reply>) : null}
        </Actions>

        {reply ? (
          <PostComment {...props} parentId={comment.entity_id} />
        ) : null}

        {comment.children && comment.children.length ? comment.children.map((child, index) => {
          return (
            <Comment {...props} key={index} comment={child} />
          );
        }) : null}
      </Body>
    </Wrapper>
  );
};

export default Comment;

const Wrapper = styled.div`
  ${PARAGRAPH}
  width: 100%:
  display: flex;
  flex-direction: row;
  padding: 10px 0 0;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    padding: 20px 0 0;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
     padding: 30px 0 0;
  }
`

const Indent = styled.div`
  position: relative;
  min-width: ${props => props.depth ? `${props.depth * 8}px` : 0};
  height: 30px;
  margin-right: 6px;
  
  &&:before {
    content: "";
    position: absolute;
    right: 0;
    top: -10px;
    width: 12px;
    height: 20px;
    border-left: 1px solid #ec3917;
    border-bottom: 1px solid #ec3917;
  }
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    min-width: ${props => props.depth ? `${props.depth * 20}px` : 0};
    height: 30px;
    margin-right: 10px;
    
    &&:before {
      top: -20px;
      width: 20px;
      height: 30px;
    }
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    min-width: ${props => props.depth ? `${props.depth * 30}px` : 0};
    height: 60px;
    margin-right: 20px;
    
    &&:before {
      top: -30px;
      width: 30px;
      height: 40px;
    }
  }
`

const Subject = styled.h4`
  ${H5}
`;

const Created = styled.span`
  ${SMALL}
  padding-left: 20px;
`

const Body = styled.div`
  width: 100%;
`

const Actions = styled.div`
  margin: -10px -15px 10px ;
`

const Reply = styled(Button)`

`

